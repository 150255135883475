/* //SSI 0112 - PFERRI - Adicionar SQUAD e melhorias visuais - 14/02/2025 */
/* //SSI 0141 - PFERRI - Adicionar tabela de condominios dos usuários e melhorias visuais - 27/03/2025 */
/* Estilo do card */
.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 800px;
  background: white;
  padding: 70px 0 0 0;
  border-radius: 12px;
  gap: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Título */
.cardTitle {
  text-align: center;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Container dos botões */
.buttonContainer {
  display: flex;
  gap: 10px;
  height: 50px;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;

}

/* Botões */
.button {
  padding: 6px 12px; /* Reduzindo o tamanho do padding */
  font-size: 12px; /* Deixando o texto menor */
  font-weight: bold;
  border-radius: 6px;
  border: 1px black;
  cursor: pointer;
  transition: 0.3s;
  background-color: white;
  color: black;
  min-width: 100px; /* Reduzindo a largura mínima */
  max-width: 160px; /* Limitando o crescimento */
  text-align: center;
  white-space: nowrap; /* Evita que o texto quebre */
  overflow: hidden;
  text-overflow: ellipsis; /* Adiciona "..." caso o texto seja grande */
}
.buttonIndividual {
  padding: 6px 12px; /* Reduzindo o tamanho do padding */
  font-size: 12px; /* Deixando o texto menor */
  font-weight: bold;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  background-color: #e0e0e0;
  color: black;
  min-width: 100px; /* Reduzindo a largura mínima */
  max-width: 160px; /* Limitando o crescimento */
  text-align: center;
  white-space: nowrap; /* Evita que o texto quebre */
  overflow: hidden;
  text-overflow: ellipsis; /* Adiciona "..." caso o texto seja grande */
}

/* Botão ativo */
.button.active {
  background-color: #007bff;
  color: white;
}

/* Container do gráfico */
.chartContainer {
  height: 450px;
  width:100% ;
  max-width: 700px;
  box-sizing: border-box;
}
.chartEtableContainer{
align-items: center;
justify-content:center;
display: flex;
flex-wrap: wrap;
flex-direction: row;
gap:20px;
/* background-color: red; */

}